import Rails from "@rails/ujs";

export default ({ bookingResourceSkuId, success, error }) => {
  Rails.ajax({
    type: "GET",
    dataType: "json",
    url: `/api/booking_resource_skus/${bookingResourceSkuId}/fetch_flights`,
    success,
    error,
  });
};
