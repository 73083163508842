import { Turbo } from "@hotwired/turbo-rails";

import updateAssociations from "../../backoffice/services/bookingResourceSkusService/updateAssociations";
import removeAssociations from "../../backoffice/services/bookingResourceSkusService/removeAssociations";

export default {
  addLoadingOverlay(targetElement) {
    const customEvent = new CustomEvent("turbo-frame-loading:start", {
      detail: {
        targetElement,
        identifier: "loading-overlay",
      },
    });

    window.dispatchEvent(customEvent);
  },

  removeLoadingOverlay(targetElement) {
    const customEvent = new CustomEvent("turbo-frame-loading:stop", {
      detail: {
        targetElement,
        identifier: "loading-overlay",
      },
    });

    window.dispatchEvent(customEvent);
  },

  updateUI() {
    return Turbo.visit(window.location.href, {
      frame: "booking_resource_skus",
    });
  },

  handleAssociation(
    draggedData,
    targetData,
    transferDirection = null,
    flightData = null
  ) {
    const associations = {
      transfer: () => {
        this.createTransferParentChildAssociation(draggedData, targetData);
      },
      accommodation: () => {
        this.createTransferAccomodationAssociation(
          draggedData,
          targetData,
          transferDirection
        );
      },
      flight: () => {
        this.createTransferFlightAssociation(
          draggedData,
          targetData,
          transferDirection,
          flightData
        );
      },
    };

    associations[draggedData.type]();
  },

  createTransferAccomodationAssociation(
    draggedData,
    targetData,
    transferDirection
  ) {
    const bookingResourceSkuId = targetData.id;
    const associatedBookingResourceSkuId = draggedData.id;

    this.createTransferAssociation(
      bookingResourceSkuId,
      associatedBookingResourceSkuId,
      "accommodation",
      draggedData,
      targetData,
      transferDirection
    );
  },

  createTransferFlightAssociation(
    draggedData,
    targetData,
    transferDirection,
    flightData
  ) {
    const bookingResourceSkuId = targetData.id;
    const associatedBookingResourceSkuId = draggedData.id;

    this.createTransferAssociation(
      bookingResourceSkuId,
      associatedBookingResourceSkuId,
      "flight",
      draggedData,
      targetData,
      transferDirection,
      flightData
    );
  },

  createTransferParentChildAssociation(draggedData, targetData) {
    let bookingResourceSkuId = null;

    if (draggedData.associatedBookingResourceSkuIdFromInverse) {
      bookingResourceSkuId =
        draggedData.associatedBookingResourceSkuIdFromInverse;
    } else {
      bookingResourceSkuId = targetData.id;
    }
    const associatedBookingResourceSkuId = draggedData.id;

    this.createTransferAssociation(
      bookingResourceSkuId,
      associatedBookingResourceSkuId,
      "parent_child",
      draggedData,
      targetData
    );
  },

  createTransferAssociation(
    bookingResourceSkuId,
    associatedBookingResourceSkuId,
    associationType,
    draggedData,
    targetData,
    transferDirection = null,
    flightData = null
  ) {
    this.addLoadingOverlay(targetData.element);

    updateAssociations({
      bookingResourceSkuId,
      associatedBookingResourceSkuId,
      associationType,
      transferDirection,
      bookingResourceSkuFlightId: flightData?.bookingResourceSkuFlightId,
      success: async () => {
        await this.updateUI();
        this.removeLoadingOverlay(targetData.element);
      },
      error: async () => {
        if (draggedData?.event?.from && draggedData.element) {
          draggedData.event.from.appendChild(draggedData.element);
        }
        await this.updateUI();
        this.removeLoadingOverlay(targetData.element);
      },
    });
  },

  breakRelationship(event) {
    const {
      associatedBookingResourceSkuIdFromInverse: bookingResourceSkuId,
      bookingResourceSkuId: associatedBookingResourceSkuId,
      associationType,
      bookingResourceSkuFlightId,
    } = event.currentTarget.dataset;

    const targetElement = event.currentTarget.closest(
      "[data-resource-sku-handle]"
    );

    this.addLoadingOverlay(targetElement);

    removeAssociations({
      bookingResourceSkuId,
      associatedBookingResourceSkuId,
      associationType,
      bookingResourceSkuFlightId,
      success: async () => {
        await this.updateUI();
        this.removeLoadingOverlay(targetElement);
      },
      error: async () => {
        await this.updateUI();
        this.removeLoadingOverlay(targetElement);
      },
    });
  },
};
