import { Controller } from "@hotwired/stimulus";

import AssociationHandlers from "./booking_resource_sku_association/association_handlers";
import ValidationHandlers from "./booking_resource_sku_association/validation_handlers";
import DragDropHandlers from "./booking_resource_sku_association/drag_drop_handlers";

export default class extends Controller {
  static targets = ["loadingOverlayTemplate", "invalidDropIndicatorTemplate"];

  initialize() {
    Object.assign(this, AssociationHandlers);
    Object.assign(this, ValidationHandlers);
    Object.assign(this, DragDropHandlers);
  }

  static showError(message) {
    const errorEvent = new CustomEvent("error:show", {
      detail: {
        message,
      },
    });
    window.dispatchEvent(errorEvent);
  }

  static showFlightSelectionModal(draggedData, targetData, transferDirection) {
    const customEvent = new CustomEvent(
      "booking-resource-sku-association:showFlightSelectionModal",
      {
        detail: {
          draggedData,
          targetData,
          transferDirection,
        },
      }
    );

    window.dispatchEvent(customEvent);
  }

  onFlightSelected(event) {
    const { flightData, contextData } = event.detail;
    const { draggedData, targetData, transferDirection } = contextData;

    this.handleAssociation(
      draggedData,
      targetData,
      transferDirection,
      flightData
    );
  }

  onFlightError(event) {
    const { message } = event.detail;
    this.constructor.showError(message || "Error selecting flight");
  }

  static showPickupDropoffModal(draggedData, targetData) {
    const customEvent = new CustomEvent(
      "booking-resource-sku-association:showPickupDropoffModal",
      {
        detail: { draggedData, targetData },
      }
    );
    window.dispatchEvent(customEvent);
  }

  onPickupDropoffSelected(event) {
    const { option, contextData } = event.detail;
    const { draggedData, targetData } = contextData;

    if (option === "cancel") {
      return;
    }

    if (draggedData.type === "flight") {
      this.constructor.showFlightSelectionModal(
        draggedData,
        targetData,
        option
      );
    } else {
      this.handleAssociation(draggedData, targetData, option);
    }
  }

  onPickupDropoffError(event) {
    const { message } = event.detail;
    this.constructor.showError(message || "Error selecting pickup/dropoff");
  }
}
