import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];

  showDetails(event) {
    event.preventDefault();

    this.modalTarget.style.display = "block";
  }

  hideDetails(event) {
    event.preventDefault();

    this.modalTarget.style.display = "none";
  }
}
