import ModalController from "./modal_controller";

export default class extends ModalController {
  static targets = ["message"];

  static values = {
    message: String,
  };

  initialize() {
    super.initialize();
    this.contextData = {};
  }

  showModal(event) {
    const { draggedData, targetData } = event.detail;
    this.contextData = { draggedData, targetData };

    this.messageTarget.textContent = this.messageValue.replace(
      "%{resource_type}",
      draggedData.resourceType
    );

    this.openModal();
  }

  dispatchSuccess(option) {
    const customEvent = new CustomEvent(
      "pickup-dropoff-modal:pickupDropoffSelected",
      {
        detail: {
          option,
          contextData: this.contextData,
        },
        bubbles: true,
      }
    );

    this.element.dispatchEvent(customEvent);
  }

  selectOption(event) {
    event.preventDefault();
    const { option } = event.currentTarget.dataset;

    this.closeModal(event);
    this.dispatchSuccess(option);
  }

  hide(event) {
    this.closeModal(event);
  }
}
