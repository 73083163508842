import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    identifier: String,
    hideAfter: { type: Number, default: 0 },
  };

  show(event) {
    if (event.detail.identifier !== this.identifierValue) return;
    const targetFrame = event.detail.targetElement.closest("turbo-frame");
    if (!targetFrame) return;

    // Set position relative on the frame
    targetFrame.style.position = "relative";

    // Clone from template
    const fragment = this.element.content.cloneNode(true);
    const overlay = fragment.querySelector("div");

    // Add the cloned overlay to the frame
    targetFrame.appendChild(overlay);

    // autohide if requested at the time of configuration
    if (this.hideAfterValue > 0) {
      setTimeout(() => {
        // NOTE: This violate DRY, ideally we should call this.hide, or raise event, but that doesn't work
        this.constructor.removeOverlay(overlay, targetFrame);
      }, this.hideAfterValue);
    }
  }

  /* eslint-disable-next-line class-methods-use-this */
  hide(event) {
    if (event.detail.identifier !== this.identifierValue) return;

    const targetFrame = event.detail.targetElement.closest("turbo-frame");
    if (!targetFrame) return;

    const overlay = targetFrame.querySelector(".absolute");
    if (overlay) {
      this.constructor.removeOverlay(overlay, targetFrame);
    }
  }

  static removeOverlay(overlay, targetFrame) {
    /* eslint-disable-next-line no-param-reassign */
    targetFrame.style.position = "";
    overlay.remove();
  }
}
