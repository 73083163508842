export default {
  validBookingResourceSkuAssociation(draggedData, targetData) {
    if (!targetData.handle.startsWith("transfer-person")) {
      return false;
    }

    switch (draggedData.type) {
      case "transfer":
        return draggedData.handle.startsWith("transfer-other");

      case "flight":
        return [null, undefined, ""].includes(
          targetData.bookingResourceSkuTransferFlightId
        );

      case "accommodation":
        return [null, undefined, ""].includes(
          targetData.bookingResourceSkuTransferAccommodationId
        );

      default:
        return false;
    }
  },
};
