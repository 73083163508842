import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "background", "editable", "header", "message"];

  openModal(event) {
    if (event) {
      event.preventDefault();

      const { header, message } = event.detail || {};

      if (this.hasHeaderTarget && header) {
        this.headerTarget.textContent = header;
      }

      if (this.hasMessageTarget && message) {
        this.messageTarget.textContent = message;
      }
    }

    this.modalTarget.style.display = "block";
    if (this.hasEditableTarget) this.editableTarget.select();
  }

  closeModal(event) {
    if (event) {
      event.preventDefault();
    }

    this.modalTarget.style.display = "none";
  }
}
