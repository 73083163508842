export default {
  showInvalidDropIndicator(targetElement) {
    const startEvent = new CustomEvent("turbo-frame-loading:start", {
      detail: {
        targetElement,
        identifier: "invalid-action-indicator",
      },
    });

    window.dispatchEvent(startEvent);
  },

  onDragDrop(event) {
    const { draggedItem, dropTarget } = event.detail;

    const draggedElement = draggedItem.querySelector(
      "[data-resource-sku-handle]"
    );

    const targetElement = dropTarget.closest("[data-resource-sku-handle]");

    if (!draggedElement || !targetElement) return;

    const draggedData = {
      id: draggedElement.dataset.bookingResourceSkuId,
      type: draggedElement.dataset.resourceSkuType,
      handle: draggedElement.dataset.resourceSkuHandle,
      resourceType: draggedElement.dataset.resourceSkuType,
      associatedBookingResourceSkuIdFromInverse:
        draggedElement.dataset.associatedBookingResourceSkuIdFromInverse,
      element: draggedElement,
      event,
    };

    const targetData = {
      id: targetElement.dataset.bookingResourceSkuId,
      type: targetElement.dataset.resourceSkuType,
      handle: targetElement.dataset.resourceSkuHandle,
      element: targetElement,
      bookingResourceSkuTransferFlightId:
        targetElement.dataset.bookingResourceSkuTransferFlightId,
      bookingResourceSkuTransferAccommodationId:
        targetElement.dataset.bookingResourceSkuTransferAccommodationId,
      event,
    };

    if (this.validBookingResourceSkuAssociation(draggedData, targetData)) {
      this.onDragDropSuccess(draggedData, targetData);
    } else {
      this.onDragDropFailure(draggedData, targetData);
    }
  },

  onDragDropSuccess(draggedData, targetData) {
    if (draggedData.type === "transfer") {
      this.handleAssociation(draggedData, targetData);
    } else {
      this.constructor.showPickupDropoffModal(draggedData, targetData);
    }
  },

  onDragDropFailure(_draggedData, targetData) {
    this.showInvalidDropIndicator(targetData.element);
  },
};
