import ModalController from "./modal_controller";
import fetchFlights from "../services/bookingResourceSkuFlightsService/fetchFlights";

export default class extends ModalController {
  static targets = ["flightList", "rowTemplate"];

  static values = {
    noFlightsAvailable: String,
    errorFetchingFlights: String,
    flightSelectionNotAvailable: String,
  };

  initialize() {
    super.initialize();
    this.bookingResourceSkuId = null;
    this.contextData = {};
  }

  showModal(event) {
    const { draggedData, targetData, transferDirection } = event.detail;

    this.bookingResourceSkuId = draggedData.id;
    this.contextData = {
      draggedData,
      targetData,
      transferDirection,
    };

    this.openModal();
  }

  dispatchError(message, error) {
    const customEvent = new CustomEvent("flight-selection-modal:error", {
      detail: {
        message,
        error,
        bookingResourceSkuId: this.bookingResourceSkuId,
        contextData: this.contextData,
      },
    });

    window.dispatchEvent(customEvent);
  }

  dispatchSuccess(flightData) {
    const customEvent = new CustomEvent(
      "flight-selection-modal:flightSelected",
      {
        detail: {
          flightData,
          bookingResourceSkuId: this.bookingResourceSkuId,
          contextData: this.contextData,
        },
        bubbles: true,
      }
    );

    this.element.dispatchEvent(customEvent);
  }

  addFlightRow(flightData) {
    const template = this.rowTemplateTarget.content.cloneNode(true);
    const row = template.querySelector("tr");

    row.dataset.bookingResourceSkuFlightId =
      flightData.bookingResourceSkuFlightId;
    row.dataset.departureAt = flightData.flight.departure_at;
    row.dataset.arrivalAt = flightData.flight.arrival_at;

    row.querySelector("[data-airline-code]").textContent =
      flightData.flight.airline_code;
    row.querySelector("[data-flight-number]").textContent =
      flightData.flight.flight_number;
    row.querySelector("[data-departure-airport]").textContent =
      flightData.flight.departure_airport;
    row.querySelector("[data-arrival-airport]").textContent =
      flightData.flight.arrival_airport;
    row.querySelector("[data-departure-time]").textContent = new Date(
      flightData.flight.departure_at
    ).toLocaleString();
    row.querySelector("[data-arrival-time]").textContent = new Date(
      flightData.flight.arrival_at
    ).toLocaleString();

    this.flightListTarget.appendChild(row);
  }

  openModal() {
    if (!this.bookingResourceSkuId) {
      this.dispatchError(this.flightSelectionNotAvailableValue, null);
      return;
    }

    super.openModal();

    fetchFlights({
      bookingResourceSkuId: this.bookingResourceSkuId,
      success: (response) => {
        const { flights } = response;
        if (!flights || !flights.length) {
          this.dispatchError(this.noFlightsAvailableValue, null);
          return;
        }
        this.flightListTarget.innerHTML = "";
        flights.forEach((flightData) => this.addFlightRow(flightData));
      },
      error: (error) => {
        this.dispatchError(this.errorFetchingFlightsValue, error);
      },
    });
  }

  selectFlight(event) {
    const row = event.target.closest("tr");
    const flightData = {
      bookingResourceSkuFlightId: row.dataset.bookingResourceSkuFlightId,
      airlineCode: row.querySelector("[data-airline-code]").textContent,
      flightNumber: row.querySelector("[data-flight-number]").textContent,
      departureAirport: row.querySelector("[data-departure-airport]")
        .textContent,
      arrivalAirport: row.querySelector("[data-arrival-airport]").textContent,
      departureAt: row.dataset.departureAt,
      arrivalAt: row.dataset.arrivalAt,
    };

    this.closeModal(event);
    this.dispatchSuccess(flightData);
  }

  hide(event) {
    this.closeModal(event);
  }
}
