import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static values = {
    group: String,
    handle: String,
  };

  connect() {
    this.sortable = new Sortable(this.element, {
      group: this.groupValue,
      sort: false,
      animation: 150,
      handle: this.handleValue,
      direction: "vertical",
      onEnd: this.handleDrop.bind(this),
    });
  }

  disconnect() {
    this.sortable.destroy();
  }

  handleDrop(event) {
    const customEvent = new CustomEvent("drag-drop:ended", {
      detail: {
        draggedItem: event.item,
        dropTarget: event.originalEvent.target,
        originalEvent: event,
      },
      bubbles: true,
    });

    this.element.dispatchEvent(customEvent);
  }
}
